/**
* Helper Component for common settings
* @author {Cognizant Technology Solutions}
* @flow
*/
import env from '../env.json';
import serviceAPI from './serviceAPI';

export const flattenObject = (obj: Object, prefix: string = '') => Object.keys(obj || {}).reduce((acc, k) => {
  const pre = prefix.length ? `${prefix}.` : '';
  if (typeof obj[k] === 'object' && Object.prototype.toString.call(obj[k]) !== '[object Array]') {
    Object.assign(acc, flattenObject(obj[k], pre + k));
  } else {
    acc[pre + k] = obj[k];
  }
  return acc;
}, {});

export const MultipleObjectFormat = (responseData: Object) => {
  const dataObject = [];
  if (responseData) {
    responseData.forEach((dObjects: Object) => {
      const useObject = dObjects.useCaseCodes;
      useObject.forEach((eachUseCase) => {
        const row = eachUseCase;
        row.isUseCaseActive = (eachUseCase.isUseCaseActive === true) ? 'Active' : 'Inactive';
        row.reasonCode = dObjects.reasonCode;
        row.reasonCodeDescription = dObjects.reasonCodeDescription;
        dataObject.push(row);
      });
    });
  }
  return dataObject;
};

export default function getflattenResponse(responseData: Object) {
  let updatedResponseData = responseData;
  const dataObject = [];

  if (responseData && typeof responseData.objects === 'object') {
    const responseDataObject = responseData.objects;
    // delete updatedResponseData.objects;

    responseDataObject.forEach((dObjects: Object, key: number) => {
      dataObject[key] = flattenObject(dObjects);
    });
    updatedResponseData = {
      ...updatedResponseData,
      objects: dataObject
    };
  }
  return updatedResponseData;
}

/**
 * This function is used for getting the api urls that varies for a functionality
 * @param {string} sourceAPI the endpoint indentifier to lookup from serviceApi.js
 * @param {string} routeParams to get the non generic url endpoints.
 * @returns {Object} the complete url for the specific app and endpoint
 */
export const getEndpointURL = (sourceAPI: string, routeParams: Object = {}) => {
  let endPointDetails = {};
  if (sourceAPI !== '' && process.env) {
    const apiEndpointDetails = serviceAPI(sourceAPI, routeParams);
    const url = apiEndpointDetails.appName ? `REACT_APP_DPOM_${apiEndpointDetails.appName}_API_${env.APISource}` : `REACT_APP_DPOM_API_${env.APISource}`;
    endPointDetails = {
      ...apiEndpointDetails,
      url: `${process.env[url] || ''}${apiEndpointDetails.endPoint}`
    };
  }
  return endPointDetails;
};

// Validates that the input string is a valid date formatted as "mm/dd/yyyy"
export function isValidDate(dateString: string) {
  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
    return false;
  }

  // Parse the date parts to integers
  const parts = dateString.split('/');
  const day = parseInt(parts[1], 10);
  const month = parseInt(parts[0], 10);
  const year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) {
    return false;
  }

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }

  // Check the range of the day.
  return day > 0 && day <= monthLength[month - 1];
}

export function isNumberValid(data: number, length: number = 0) {
  const decimalRegex = /^\d+$/;
  if (typeof data !== 'number') return false;
  if (length !== 0) {
    if (Number(data).toString().trim().length !== length) return false;
  }
  if (!decimalRegex.test(`${data}`)) return false;
  return true;
}

export function isNotnullCheck(val: string) {
  if (val === undefined || val === null || val.trim() === '') return false;
  return true;
}

export function isValidGACUseCaseCode(useCaseCode: string) {
  if (useCaseCode === undefined) return false;
  if (Number(useCaseCode) < 1) return false;
  const values = useCaseCode.toString().split('.');
  const decimal = values[1];
  if (decimal === undefined) return false;
  if (decimal.length !== 2) return false;
  return true;
}

export function isValidGACReasonCode(gacReasonCode: string) {
  const decimalWithLengthTwoRegex = /^\d{1,2}$/;
  if (gacReasonCode === undefined || gacReasonCode === null || gacReasonCode === '') return false;
  if (Number(gacReasonCode) < 1) return false;
  if (!decimalWithLengthTwoRegex.test(gacReasonCode)) return false;
  return true;
}

export function getJsDateFromExcel(excelDate: number) {
  // JavaScript dates can be constructed by passing milliseconds
  // since the Unix epoch (January 1, 1970) example: new Date(12312512312);

  // 1. Subtract number of days between Jan 1, 1900 and Jan 1, 1970, plus 1
  // (Google "excel leap year bug")
  // 2. Convert to milliseconds.
  if (!excelDate || excelDate === 2958101 || `${excelDate}`.trim() === '') {
    return '';
  }
  const date = new Date((excelDate - (25567 + 2)) * 86400 * 1000);
  const monthText = (date.getMonth() > 8) ? (date.getMonth() + 1) : (`0${date.getMonth() + 1}`);
  const dayText = (date.getDate() > 9) ? date.getDate() : (`0${date.getDate()}`);
  const yearText = date.getFullYear();
  // eslint-disable-next-line
  if (isNaN(date.getTime())) {
    return `${excelDate}`;
  }
  return date ? `${monthText}/${dayText}/${yearText}` : '';
}

/**
 * This methods helps you to validate the domain of email id
 * It bascically checks if its valid nike mail id
 * returns the boolean value.
 * @param {string} emailId which needs to be validated
 * @returns {boolean} based on the validation
 */

export const validateStakeHolderEmailDomain = (emailId: string = '') => {
  let validated = false;
  const emailSubStrings = emailId.split('@');
  if (emailSubStrings.length === 2 && (emailSubStrings[1].toLowerCase() === 'nike.com')) {
    validated = true;
  }
  return validated;
};

/**
 * This methods helps you to validate email id
 * returns the boolean value.
 * @param {string} emailId which needs to be validated
 * @returns {boolean} based on the validation
 */

export const validateStakeHolderEmail = (emailId: string) => {
  let emailValidation = false;
  const emailRegx = /^[-!#$%&'*+0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  if (emailRegx.test(String(emailId).toLowerCase())) {
    emailValidation = true;
  }
  return emailValidation;
};
function formatAMPM(time) {
  let hours = Number(time.split(':')[0]);
  const minutes = time.split(':')[1];
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours %= 12;
  hours = !hours ? 12 : hours;
  const strTime = `${hours}:${minutes} ${ampm}`;
  return strTime;
}

export const convertDateFormat = (date: string, newFormat: string, type?: string) => {
  let newModifiedDate = '-';
  if ((date || '').trim()) {
    const DateStr = date.split('T')[0].split('-');
    const day = DateStr[2];
    const month = DateStr[1];
    const year = DateStr[0];
    const time = ((
      type === 'poPdf'
      || type === 'sireTab'
      || type === 'upload'
      || type === 'modifiedDate'
      || type === 'lastModifiedDate'
      || type === 'featureFlagDate'
    )
      && date.split('T')[1]) ? date.split('T')[1].split('.')[0] : '';
    const modifiedTime = formatAMPM(time);
    switch (newFormat) {
      case 'yyyy/dd/MM':
        newModifiedDate = `${year}/${day}/${month}`;
        break;
      case 'dd/MM/yyyy':
        newModifiedDate = `${day}/${month}/${year}`;
        break;
      case 'yyyy/MM/dd':
        newModifiedDate = `${year}/${month}/${day}`;
        break;
      case 'MM/yyyy/dd':
        newModifiedDate = `${month}/${year}/${day}`;
        break;
      case 'dd/yyyy/MM':
        newModifiedDate = `${day}/${year}/${month}`;
        break;
      case 'MM/dd/yyyy Thh/mm/ss':
        newModifiedDate = `${month}/${day}/${year} T${time}`;
        break;
      case 'yyyy/MM/dd Thh/mm/ss':
        newModifiedDate = `${year}-${month}-${day} ${modifiedTime}`;
        break;
      default:
        newModifiedDate = `${month}/${day}/${year}`;
    }
  }
  return newModifiedDate;
};
export function dateCheck(dateString: string) {
  const firstDate: Date = new Date('01/01/1900');
  const currentDate: Date = new Date(dateString);
  const lastDate: Date = new Date('01/01/2100');
  return !(currentDate <= lastDate && currentDate >= firstDate);
}
export function dateStringFormatter(format: string) {
  const formatString = format.toLowerCase();
  return formatString.replace('mm', 'MM');
}
export function getArrayofYears(startYear: number, endYear: number) {
  const years = [];
  for (let i = startYear; i <= endYear; i += 1) {
    years.push({ id: `${i}`, value: `${i}` });
  }
  return years;
}
/**
 * The converts the date to iso string without changing the date
 * @param {Date} date
 * @param {Object} TimeObject
 * @returns ISO date
 */

export const getISODate = (inputDate: Object) => {
  if (!inputDate) {
    return null;
  }
  let date = null;
  if (inputDate instanceof Date) {
    date = inputDate;
  } else {
    date = new Date(inputDate);
  }
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export function cryptoRand() {
  const randomBuffer = new Uint32Array(1);
  (window.crypto || window.msCrypto).getRandomValues(randomBuffer);
  return (randomBuffer[0] / (0xffffffff + 1));
}

export function titleCase(str: string) {
  return str.split(' ').map((s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()).join(' ');
}

export function checkForPrimarySecondary(row: Object, headCell: Object) {
  return (!((row[headCell.primary] || '').toString().trim() === '' || row[headCell.primary] === undefined)) && (!((row[headCell.secondary] || '').trim() === '' || row[headCell.secondary] === undefined));
}

export function checkIfOnlySecondaryValueExist(row: Object, headCell: Object) {
  return ((row[headCell.primary] || '').trim() === '' || row[headCell.primary] === undefined) && (!((row[headCell.secondary] || '').trim() === '' || row[headCell.secondary] === undefined));
}

export function checkMapFields(row: Object, headCell: Object, emptyCellPlaceholder: string) {
  let mappedFields = '';
  if (checkForPrimarySecondary(row, headCell)) {
    mappedFields = `${row[headCell.primary]} | ${row[headCell.secondary]}`;
  } else if ((!((row[headCell.primary] || '').trim() === '' || row[headCell.primary] === undefined)) && ((row[headCell.secondary] || '').trim() === '' || row[headCell.secondary] === undefined)) {
    mappedFields = `${row[headCell.primary]}`;
  } else if (checkIfOnlySecondaryValueExist(row, headCell)) {
    mappedFields = ` ${row[headCell.secondary]}`;
  } else {
    mappedFields = emptyCellPlaceholder;
  }
  return mappedFields;
}
export function validateFactoryCode(value: any) {
  const regex = /^[0-9a-zA-Z]{1,6}$/;
  if (!regex.test(value.value)) {
    return 'Enter correct 6 digit max Code value.';
  }
  return '';
}
export function validateEmailId(value: string) {
  if (!(validateStakeHolderEmail(value) && validateStakeHolderEmailDomain(value))) {
    return 'Please enter Valid Nike Email Id.';
  }
  return '';
}
export function validateManagingOffice(value: string) {
  const regex = /^[0-9a-zA-Z]{1,8}$/;
  if (!regex.test(value) && value) {
    return 'Enter 8 Character max length.';
  }
  return '';
}
export function validateAlphanumeric(value: string) {
  const regex = /^[a-zA-Z0-9_. ]+$/;
  if (!regex.test(value) && value) {
    return 'Enter Valid Input Value.';
  }
  return '';
}
export function validateMultiCheckbox(value: Object) {
  if (value.length < 1) {
    return 'Enter Valid Input Value.';
  }
  return '';
}
export function validateDateTime(value: Object) {
  if (value === null || value === '') {
    return 'Enter Valid DateTime Value.';
  }
  return '';
}
