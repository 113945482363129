/**
* Helper Component for data
* @author {Cognizant Technology Solutions}
*/
import collabReportFieldMapping from '../constants/fieldMappings/collabReportFieldMapping';
import fieldMapping from '../constants/fieldMappings/poSearchFieldMapping';
import messageText from '../constants/messageText';
import ppmReportFieldMapping from '../constants/fieldMappings/ppmReportFieldMapping';
import ppmReportFieldProperties from '../constants/fieldProperties/ppmReportFieldProperties';
import lineSummaryReportFieldProperties from '../constants/fieldProperties/lineSummaryReportFieldProperties';
import collabReportFieldProperties from '../constants/fieldProperties/collabReportFieldProperties';
import poSearchFieldProperties from '../constants/fieldProperties/poSearchFieldProperties';
import gacEventErrorTableFieldProperties from '../constants/fieldProperties/gacEventErrorTableFieldProperties';
import gacEventTableFieldProperties from '../constants/fieldProperties/gacEventTableFieldProperties';
import gacUpdateTableFieldProperties from '../constants/fieldProperties/gacUpdateTableFieldProperties';
import myRequestTableFieldProperties from '../constants/fieldProperties/myRequestTableFieldProperties';
import poChangeReportFieldProperties from '../constants/fieldProperties/poChangeReportFieldProperties';
import poEventErrorTableFieldProperties from '../constants/fieldProperties/poEventErrorTableFieldProperties';
import sireProfileTableFieldProperties from '../constants/fieldProperties/sireProfileTableFieldProperties';
import sireSummaryTableFieldProperties from '../constants/fieldProperties/sireSummaryTableFieldProperties';
import wipTableFieldProperties from '../constants/fieldProperties/wipTableFieldProperties';
import poChangeReportFieldMapping from '../constants/fieldMappings/poChangeReportFieldMapping';
import conversationThreadsFieldProperties from '../constants/fieldProperties/conversationThreadsFieldProperties';
import conversationThreadReportFieldProperties from '../constants/fieldProperties/conversationThreadReportFieldProperties';
import pmoDecFieldProperties from '../constants/fieldProperties/pmoDecFieldProperties';
import operationalMetricsFieldProperties from '../constants/fieldProperties/operationalmetricsFieldProperties';
import geoGlobalFieldProperties from '../constants/fieldProperties/geoGlobalFieldProperties';
import geoGlobalFieldMapping from '../constants/fieldMappings/geoGlobalFieldMapping';
import lineSummaryReportFieldMapping from '../constants/fieldMappings/lineSummaryReportFieldMapping';
import airFreightReportFieldProperties from '../constants/fieldProperties/airFreightReportFieldProperties';
import gacChangeHistoryReportFieldProperties from '../constants/fieldProperties/gacChangeHistoryReportFieldProperties';
import conversationCategoryCodesFieldProperties from '../constants/fieldProperties/conversationCategoryCodesFieldProperties';
import conversationThreadReportFieldMapping from '../constants/fieldMappings/conversationThreadReportFieldMapping';
import gacReasonCodeFieldProperties from '../constants/fieldProperties/gacReasonCodeFieldProperties';
import gacReasonUsecasesFieldProperties from '../constants/fieldProperties/gacReasonUsecasesFieldProperties';
import geoEmailGroupFieldProperties from '../constants/fieldProperties/geoEmailGroupFieldProperties';
import featureFlagFieldProperties from '../constants/fieldProperties/featureFlagFieldProperties';
import airFreightReportFieldMapping from '../constants/fieldMappings/airFreightReportFieldMapping';

const propertiesMappingCollection = {
  poSearchFieldProperties,
  collabReportFieldProperties,
  ppmReportFieldProperties,
  lineSummaryReportFieldProperties,
  sireProfileTableFieldProperties,
  poChangeReportFieldProperties,
  gacEventErrorTableFieldProperties,
  gacEventTableFieldProperties,
  gacUpdateTableFieldProperties,
  myRequestTableFieldProperties,
  poEventErrorTableFieldProperties,
  sireSummaryTableFieldProperties,
  wipTableFieldProperties,
  conversationThreadsFieldProperties,
  conversationThreadReportFieldProperties,
  operationalMetricsFieldProperties,
  pmoDecFieldProperties,
  geoGlobalFieldProperties,
  geoEmailGroupFieldProperties,
  airFreightReportFieldProperties,
  conversationCategoryCodesFieldProperties,
  gacReasonCodeFieldProperties,
  gacChangeHistoryReportFieldProperties,
  gacReasonUsecasesFieldProperties,
  featureFlagFieldProperties
};

/**
 * The Method Updates the valid date formts
 * @param {number} date getting the date value from collab objects
 * @returns {string} valid date format to display
 *
 */
export const getDateInYYYYMMDD = (selectedDate: Object) => {
  // $FlowIssue
  const seletedDate = new Date(selectedDate);
  const month = (seletedDate.getMonth() + 1).toString().padStart(2, '0');
  const day = seletedDate.getDate().toString().padStart(2, '0');
  const fullYear = seletedDate.getFullYear();
  return `${fullYear}-${month}-${day}`;
};

/**
 * This method is used for getting the field properties with
 * the field key for looking up the POFileds array.
 * returns the object from the table header array.
 * @param {string} field for which the object to be returned
 * @returns {Object} fieldProperties for the corresponding key
 */
export const getFieldProperties = (field: string, type?: string) => {
  if (type) {
    const currentPropertiesMapping = propertiesMappingCollection[`${type}FieldProperties`] || {};
    return currentPropertiesMapping[field] || {};
  }
  const matchedPropertyMappingKey = Object.keys(propertiesMappingCollection)
    .find((collectionKey) => propertiesMappingCollection[collectionKey][field]);
  return ((propertiesMappingCollection[matchedPropertyMappingKey] || {})[field] || {});
};

/**
 * field fieldTemplate for request, selects a predified list of operators
 * that would be selected by setting the fieldName using name (setter)
 * @returns {
 *  fieldName,
 *  operator,
 *  fieldValue,
 *  name: setter -> fieldName && operator,
 *  value: setter -> fieldValue
 * }
 * @flow
 */
export const getFieldTemplate = () => {
  const getFieldMap = (fieldName: string) => {
    switch (fieldName) {
      // case fieldMapping.CREATE_DATE: {
      //   return {
      //     operator: 'LAST_MONTH',
      //     type: 'DYNAMIC'
      //   };
      // }
      // case airFreightReportFieldMapping.AIR_FREIGHT_DPOM_LINE_ITEM_STATUS:
      case airFreightReportFieldMapping.AIR_FREIGHT_PLANT_CODE:
      case conversationThreadReportFieldMapping.CONVO_THREAD_CONVO_TOPIC_CODE:
      case 'collaborationTopic.code':
      case ppmReportFieldMapping.PPM_REPORT_GAC_REASON_CODE:
      case lineSummaryReportFieldMapping.LINE_SUMMARY_GAC_REASON_CODE:
      case ppmReportFieldMapping.PPM_REPORT_DIVISION_CODE:
      case ppmReportFieldMapping.PPM_REPORT_PURCHASE_ORG_CODE:
      case poChangeReportFieldMapping.PO_CHANGE_DOC_TYPE_CODE:
      case poChangeReportFieldMapping.PO_CHANGE_VENDOR_CODE:
      case poChangeReportFieldMapping.PO_CHANGE_CHANGED_TYPE:
      case poChangeReportFieldMapping.PO_CHANGE_SEASON_YEAR:
      case poChangeReportFieldMapping.PO_CHANGE_SEASON_CODE:
      case ppmReportFieldMapping.PPM_REPORT_DOC_TYPE_CODE:
      case collabReportFieldMapping.REPORT_VENDOR_CODE:
      case collabReportFieldMapping.REPORT_PO_DOC_TYPE:
      case fieldMapping.PMO_DEC_CODE:
      case ppmReportFieldMapping.PPM_REPORT_SEASON_CODE:
      case ppmReportFieldMapping.PPM_REPORT_SEASON_YEAR:
      case fieldMapping.DIVISION:
      case fieldMapping.YEAR:
      case fieldMapping.SEASON:
      case fieldMapping.PURCHASE_ORG_CODE:
      case fieldMapping.PURCHASING_DOCUMENT_TYPE_CODE:
      case geoGlobalFieldMapping.GEO_GLOBAL_DOCTYPE:
      case fieldMapping.VENDOR_CODE: {
        return {
          operator: '=',
          getFieldValue: (data: Array<Object>) => data.map((each) => each.id || each)
        };
      }
      case collabReportFieldMapping.REPORT_DATE_APPROVED_OR_REJECTED:
      case collabReportFieldMapping.REPORT_REQUEST_INITIATED_DATE:
      case collabReportFieldMapping.REPORT_DOCUMENT_DATE:
      case collabReportFieldMapping.REPORT_CREATED_DATE:
      case ppmReportFieldMapping.PPM_REPORT_DOC_DATE:
      case ppmReportFieldMapping.PPM_REPORT_CHANGED_DATE:
      case ppmReportFieldMapping.PPM_REPORT_MRGAC:
      case ppmReportFieldMapping.PPM_REPORT_OGAC:
      case ppmReportFieldMapping.PPM_REPORT_GAC:
      case ppmReportFieldMapping.PPM_REPORT_ORIGIN_RECEIPT_ACTUAL_DATE:
      case ppmReportFieldMapping.PPM_REPORT_CREATED_DATE:
      case ppmReportFieldMapping.PPM_REPORT_ACCEPT_PO_ACTUAL_DATE:
      case ppmReportFieldMapping.PPM_REPORT_FACTORY_DELIVERY_ACTUAL_DATE:
      case ppmReportFieldMapping.PPM_REPORT_CURRENT_EVENT_DATE:
      case ppmReportFieldMapping.PPM_REPORT_NEXT_EVENT_DATE:
      case poChangeReportFieldMapping.PO_CHANGE_CHANGE_DATE:
      case poChangeReportFieldMapping.PO_CHANGE_DOCUMENT_DATE:
      case poChangeReportFieldMapping.PO_CHANGE_GAC_DATE:
      case poChangeReportFieldMapping.PO_CHANGE_OGAC_DATE:
      case fieldMapping.CREATE_DATE:
      case fieldMapping.DOCUMENT_DATE:
      case fieldMapping.OGAC:
      case fieldMapping.GAC:
      case fieldMapping.MRGAC:
      case fieldMapping.PO_ACCEPTANCE_DATE: {
        return { operator: '>=' };
      }
      default: {
        return { operator: '=' };
      }
    }
  };

  return {
    getFieldMap,
    fieldName: '',
    operator: '',
    fieldValue: null,
    set name(fieldName) {
      this.fieldName = fieldName;
      const fieldMap = getFieldMap(fieldName);
      this.operator = fieldMap.operator;
      // if (fieldMap.type) {
      //   this.type = fieldMap.type;
      // }
    },
    set value(data) {
      const fieldMap = getFieldMap(this.fieldName);
      this.fieldValue = fieldMap.getFieldValue ? fieldMap.getFieldValue(data) : data;
    }
  };
};

/**
 * function to convert the input fields data into contract format
 * for the date range fields, two fieldTemplate will be created for from and to
 * @param {Object} inputData
 * @returns {Array<Object>} in contract request format
 */

export function getRequestForDynamicRequest(
  inputData: Object,
  fieldKey: string,
  searchRequestData: Object
) {
  if (inputData[fieldKey].staticRange) {
    const fieldTemplate = getFieldTemplate();
    fieldTemplate.name = fieldKey; // e.g 'poHeader.partnerNumber'
    fieldTemplate.type = 'DYNAMIC';
    fieldTemplate.operator = inputData[fieldKey].staticRange;
    delete fieldTemplate.fieldValue;
    searchRequestData.push(fieldTemplate);
  } else {
    if (inputData[fieldKey].startFromDays || inputData[fieldKey].startFromDays === 0) {
      const fieldTemplate = getFieldTemplate();
      fieldTemplate.name = fieldKey; // e.g 'poHeader.partnerNumber'
      fieldTemplate.type = 'DYNAMIC';
      fieldTemplate.operator = '>=';
      fieldTemplate.fieldValue = inputData[fieldKey].startFromDays.toString();
      searchRequestData.push(fieldTemplate);
    }
    if (inputData[fieldKey].endFromDays || inputData[fieldKey].endFromDays === 0) {
      const toFieldTemplate = getFieldTemplate();
      toFieldTemplate.name = fieldKey; // e.g 'poHeader.partnerNumber'
      toFieldTemplate.type = 'DYNAMIC';
      toFieldTemplate.operator = '<=';
      toFieldTemplate.fieldValue = inputData[fieldKey].endFromDays.toString();
      searchRequestData.push(toFieldTemplate);
    }
  }
}
function checkConditionForToDate(inputData: Object, fieldKey: string) {
  return inputData[fieldKey].to
    && (
      typeof inputData[fieldKey].to === 'object'
      || typeof inputData[fieldKey].to === 'string'
    )
    && !Number.isNaN(new Date(inputData[fieldKey].to).getDate());
}

function checkConditionForFromDate(inputData: Object, fieldKey: string) {
  return inputData[fieldKey].from
    && (
      typeof inputData[fieldKey].from === 'object'
      || typeof inputData[fieldKey].from === 'string'
    )
    && !Number.isNaN(new Date(inputData[fieldKey].from).getDate());
}
export function dateRangeRequestFormation(
  inputData: Object,
  fieldKey: string,
  searchRequestData: Object,
  fieldProperties: Object
) {
  if ((fieldProperties.requestDataType === 'dynamicDate')
    && inputData[fieldKey]
    && typeof inputData[fieldKey] === 'object'
    && Object.keys(inputData[fieldKey]).length > 0
    && inputData[fieldKey].isDynamic) {
    getRequestForDynamicRequest(inputData, fieldKey, searchRequestData);
  } else {
    if (checkConditionForFromDate(inputData, fieldKey)) {
      const dateFrom = new Date(inputData[fieldKey].from);
      const dateFromTemplate = getFieldTemplate();
      dateFromTemplate.name = fieldKey;
      dateFromTemplate.value = (fieldProperties.type === 'datetime') ? `${dateFrom.getFullYear()}-${(dateFrom.getMonth() + 1).toString()
        .padStart(2, '0')}-${dateFrom.getDate().toString().padStart(2, '0')}T00:00:00.000Z`
        : `${dateFrom.getFullYear()}-${(dateFrom.getMonth() + 1).toString()
          .padStart(2, '0')}-${dateFrom.getDate().toString().padStart(2, '0')}`;
      dateFromTemplate.operator = '>=';
      searchRequestData.push(dateFromTemplate);
    }
    if (checkConditionForToDate(inputData, fieldKey)) {
      const dateTo = new Date(inputData[fieldKey].to);
      const dateToTemplate = getFieldTemplate();
      dateToTemplate.name = fieldKey; // e.g 'poHeader.partnerNumber'
      dateToTemplate.value = fieldProperties.type === 'datetime' ? `${dateTo.getFullYear()}-${(dateTo.getMonth() + 1).toString().padStart(2, '0')}-${dateTo.getDate().toString().padStart(2, '0')}T23:59:59.000Z`
        : `${dateTo.getFullYear()}-${(dateTo.getMonth() + 1).toString().padStart(2, '0')}-${dateTo.getDate().toString().padStart(2, '0')}`;
      dateToTemplate.operator = '<=';
      searchRequestData.push(dateToTemplate);
    }
  }
}
export const mapInputFieldsToRequest = (inputData: Object, mappingType: string) => {
  const searchRequestData = [];
  Object.keys(inputData).forEach((fieldKey: string) => {
    const fieldProperties = getFieldProperties(fieldKey, mappingType);
    if (
      (
        fieldKey === airFreightReportFieldMapping.AIR_FREIGHT_DPOM_LINE_ITEM_STATUS
        || fieldKey === airFreightReportFieldMapping.AIR_FREIGHT_REQUEST_STATUS
      )
      && Array.isArray(inputData[fieldKey])
      && inputData[fieldKey].length > 0
    ) {
      const fieldTemplate = getFieldTemplate();
      fieldTemplate.name = fieldKey; // e.g 'productCode'
      fieldTemplate.fieldValue = inputData[fieldKey].map((each) => each.id || each);
      fieldTemplate.operator = '=';
      searchRequestData.push(fieldTemplate);
    } else if (fieldKey === fieldMapping.PO_LINE_ITEM_STATUS && inputData[fieldKey].includes(',')) {
      const fieldTemplate = getFieldTemplate();
      fieldTemplate.name = fieldKey; // e.g 'poHeader.partnerNumber'
      // fieldTemplate.operator = 'in';
      fieldTemplate.value = inputData[fieldKey].split(',');
      searchRequestData.push(fieldTemplate);
    } else if (!(fieldProperties.type === 'date' || fieldProperties.type === 'datetime')) {
      if ((typeof inputData[fieldKey] === 'string' && inputData[fieldKey])
        || (inputData[fieldKey]
          && typeof inputData[fieldKey] === 'object'
          && Object.keys(inputData[fieldKey]).length > 0)) {
        const fieldTemplate = getFieldTemplate();
        fieldTemplate.name = fieldKey; // e.g 'poHeader.partnerNumber'
        fieldTemplate.value = inputData[fieldKey];
        searchRequestData.push(fieldTemplate);
      }
    } else {
      dateRangeRequestFormation(inputData, fieldKey, searchRequestData, fieldProperties);
    }
  });

  return searchRequestData;
};

export function dateTimeRequestData(inputData, fieldKey, searchRequestData, type) {
  if (typeof inputData[fieldKey] === 'string' && inputData[fieldKey]) {
    const fieldTemplate = getFieldTemplate();
    fieldTemplate.name = fieldKey; // e.g 'productCode'
    fieldTemplate.fieldValue = inputData[fieldKey];
    fieldTemplate.operator = '=';
    searchRequestData.push(fieldTemplate);
  } else if ((typeof inputData[fieldKey] === 'object'
    && Object.keys(inputData[fieldKey]).length > 0)) {
    const fieldTemplate = getFieldTemplate();
    fieldTemplate.name = fieldKey; // e.g categoryCode'
    fieldTemplate.fieldValue = inputData[fieldKey].map((filterValue) => {
      const indexOfPipe = filterValue.toString().indexOf('|');
      const poFieldProperty = getFieldProperties(fieldKey, type);
      if (indexOfPipe > -1) {
        const primaryFilterValue = filterValue.substr(0, indexOfPipe).trim();
        const primaryValueAsNumber = Number(primaryFilterValue);
        if (poFieldProperty && poFieldProperty.type === 'number' && !Number.isNaN(primaryValueAsNumber)) {
          return primaryValueAsNumber;
        }
        return primaryFilterValue;
      }
      return filterValue;
    });
    fieldTemplate.operator = '=';
    searchRequestData.push(fieldTemplate);
  }
}

export const mapFiltersWithDatesToRequest = (
  inputData: Object, mappingType: string, type: string = undefined
) => {
  const searchRequestData = [];
  Object.keys(inputData).forEach((fieldKey: string) => {
    const fieldProperties = getFieldProperties(fieldKey, mappingType);
    if (!(fieldProperties.type === 'date' || fieldProperties.type === 'datetime')) {
      dateTimeRequestData(inputData, fieldKey, searchRequestData, type);
    } else {
      dateRangeRequestFormation(inputData, fieldKey, searchRequestData, fieldProperties);
    }
  });

  return searchRequestData;
};

/**
 * converts selected fitlers data from the store to
 * contract request format
 * @param {Object} selectedFilters - filters Object from the store
 * @returns {Array<Object>} in contract request format
 * Used for filter property of the request
 */
export const mapFiltersToRequest = (selectedFilters: Object = {}, type: string = undefined) => {
  const filtersRequestData = [];
  Object.keys(selectedFilters).forEach((filter: string) => {
    if (selectedFilters[filter]
      && typeof selectedFilters[filter] === 'object'
      && Object.keys(selectedFilters[filter]).length > 0) {
      const fieldTemplate = getFieldTemplate();
      fieldTemplate.fieldName = filter; // e.g 'poHeader.partnerNumber'
      fieldTemplate.fieldValue = selectedFilters[filter].map((filterValue) => {
        const indexOfPipe = filterValue.toString().indexOf('|');
        const poFieldProperty = getFieldProperties(filter, type);
        if (indexOfPipe > -1) {
          const primaryFilterValue = filterValue.substr(0, indexOfPipe).trim();
          const primaryValueAsNumber = Number(primaryFilterValue);
          if (poFieldProperty && poFieldProperty.type === 'number' && !Number.isNaN(primaryValueAsNumber)) {
            return primaryValueAsNumber;
          }
          return primaryFilterValue;
        }
        return filterValue;
      });
      fieldTemplate.operator = '=';
      filtersRequestData.push(fieldTemplate);
    }
  });

  return filtersRequestData;
};

/**
 * This function is used for converting the request body to the format of the UI fields
 * Mainly used for the saved search flow
 * @param {Object} poSearchRequest in the format of the request body
 * @returns {Object} the object that UI searchCriteria component can understand
 */
function mapToFieldValue(field) {
  return field.fieldValue.map
    ? field.fieldValue.map((value) => ({ id: value }))
    : [];
}

export function getDynamicFieldValue(field: string, inputFieldsData: Object) {
  if (!field.fieldValue) {
    /* eslint-disable no-param-reassign */
    inputFieldsData[field.fieldName] = {
      isDynamic: true,
      staticRange: field.operator
    };
  } else {
    const fieldData = {
      ...(inputFieldsData[field.fieldName] || {}),
      isDynamic: true,
      [(field.operator === '>=') ? 'startFromDays' : 'endFromDays']: field.fieldValue
    };
    /* eslint-disable no-param-reassign */
    inputFieldsData[field.fieldName] = fieldData;
  }
}

export const mapRequestToInputFields = (poSearchRequest: Object) => {
  const inputFieldsData = {};
  poSearchRequest.forEach((field) => {
    const fieldProperty = getFieldProperties(field.fieldName);
    if (
      field.fieldName === ppmReportFieldMapping.PPM_REPORT_VENDOR
      || field.fieldName === ppmReportFieldMapping.PPM_REPORT_GAC_REASON_CODE
      || field.fieldName === lineSummaryReportFieldMapping.LINE_SUMMARY_GAC_REASON_CODE
      || field.fieldName === ppmReportFieldMapping.PPM_REPORT_PMO_DEC_CODE
      || field.fieldName === collabReportFieldMapping.REPORT_VENDOR_CODE
      || field.fieldName === fieldMapping.VENDOR_CODE
      || field.fieldName === fieldMapping.PMO_DEC_CODE
      || field.fieldName === collabReportFieldMapping.REPORT_PO_DOC_TYPE
      || field.fieldName === ppmReportFieldMapping.PPM_REPORT_DOC_TYPE_CODE
      || field.fieldName === poChangeReportFieldMapping.PO_CHANGE_DOC_TYPE_CODE
      || field.fieldName === poChangeReportFieldMapping.PO_CHANGE_VENDOR_CODE
      || field.fieldName === poChangeReportFieldMapping.PO_CHANGE_CHANGED_TYPE
      || field.fieldName === poChangeReportFieldMapping.PO_CHANGE_SEASON_YEAR
      || field.fieldName === poChangeReportFieldMapping.PO_CHANGE_SEASON_CODE
      || field.fieldName === fieldMapping.DIVISION
      || field.fieldName === fieldMapping.YEAR
      || field.fieldName === fieldMapping.SEASON
      || field.fieldName === fieldMapping.PURCHASE_ORG_CODE
      || field.fieldName === fieldMapping.PURCHASING_DOCUMENT_TYPE_CODE
      || field.fieldName === geoGlobalFieldMapping.GEO_GLOBAL_DOCTYPE
    ) {
      inputFieldsData[field.fieldName] = mapToFieldValue(field);
    } else if (
      field.fieldName === fieldMapping.PO_LINE_ITEM_STATUS && field.fieldValue instanceof Array
    ) {
      inputFieldsData[field.fieldName] = field.fieldValue.join(',');
    } else if (field.type === 'DYNAMIC') {
      getDynamicFieldValue(field, inputFieldsData);
    } else if (fieldProperty.type === 'date' || fieldProperty.type === 'datetime') {
      if (!inputFieldsData[field.fieldName]) {
        inputFieldsData[field.fieldName] = {
          from: null,
          to: null
        };
      }
      const dateOnly = (field.fieldValue && field.fieldValue.includes('T'))
        ? field.fieldValue.split('T')[0]
        : field.fieldValue || '';
      if (field.operator === '>=') {
        inputFieldsData[field.fieldName] = {
          ...(inputFieldsData[field.fieldName] || {}),
          from: new Date(`${dateOnly}T00:00:00.000`)
        };
      } else if (field.operator === '>') {
        inputFieldsData[field.fieldName] = {
          ...(inputFieldsData[field.fieldName] || {}),
          from: new Date(`${dateOnly}T00:00:00.000`)
        };
      } else {
        inputFieldsData[field.fieldName] = {
          ...(inputFieldsData[field.fieldName] || {}),
          to: new Date(`${dateOnly}T23:59:59.999`)
        };
      }
    } else if (field.fieldName === fieldMapping.DELETION_INDICATOR) {
      // this is intentional
      // noop
    } else {
      inputFieldsData[field.fieldName] = field.fieldValue;
    }
  });
  return inputFieldsData;
};

/**
 * Traverses the response object with the field key for getting unique
 * values of the data rows.
 * @param {Array<Object>} filtersFromResponse - the filters property in the
 * contract response received.
 * @returns {Object} in the format for consumtions of fitler inputs in UI
 */
export const getFilterValuesFromResponse = (filtersFromResponse: Array<Object> = []) => {
  const filterValues = {};
  filtersFromResponse.forEach((filter) => {
    filterValues[filter.filterName] = filter.filterValues.map((each) => {
      let filterValue = each.primary;
      if (each.secondary) {
        filterValue += ` | ${each.secondary}`;
      }
      return filterValue;
    }).filter((each) => each);
  });
  return filterValues;
};

/**
 * Returns the filters in response for the request body after converting
 * from the filters reducer format
 * @param {Object} selectedFilters in the format of filters reducer
 * @param {string} type for the mapping type of the current flow
 * @returns {Array<Object>} of the filtersInResponse request body
 */
export const getFiltersInResponseRequestBody = (
  selectedFilters: Object = {}, type: string = 'poSearch'
) => {
  const currentPropertiesMapping = propertiesMappingCollection[`${type}FieldProperties`] || {};
  return Object.keys(selectedFilters)
    .filter((filter) => selectedFilters[filter])
    .map((filter) => {
      const filterInResponse = {};
      const fieldProperties = currentPropertiesMapping[filter] || {};
      if (!fieldProperties.primary) {
        return null;
      }
      filterInResponse.primary = fieldProperties.primary;
      if (fieldProperties.secondary) {
        filterInResponse.secondary = fieldProperties.secondary;
      }
      return filterInResponse;
    })
    .filter((element) => element);
};

export const getSireRequestBodyFromPOResults = (
  poDataRows: Array<Object> = [],
  ids: Array<string> = []
) => {
  const selectedRows = poDataRows.filter((row) => ids.includes(row[fieldMapping.SIZE_ID]));

  return {
    poData: selectedRows.map((row) => ({
      poNumber: row[fieldMapping.PURCHASE_ORDER_NUMBER],
      itemNumber: row[fieldMapping.PO_LINE_ITEM_NUMBER],
      scheduleLineItemNumber: row[fieldMapping.SCHEDULE_LINE_ITEM_NUMBER]
    }))
  };
};

/**
 * This function can be used for sorting an array of objects.
 * This supports Array of primitive types also, yet it supports only
 * flattened objects for now.
 * @param {Array<Object>} data to be sorted
 * @param {string} sortBy optional parameter propertyName with which the data should be sorted
 * @param {boolean} isOrderDesc whether the order should be descending
 */
export const sortData = (
  data: Array<Object> = [], sortBy?: string = '', isOrderDesc: boolean = false
) => {
  const comparer = (firstElement, secondElement) => {
    let firstElementValue = firstElement;
    let secondElementValue = secondElement;
    if (sortBy) {
      firstElementValue = firstElement[sortBy];
      secondElementValue = secondElement[sortBy];
    }

    if (isOrderDesc) {
      return (secondElementValue > firstElementValue) ? 1 : -1;
    }
    return (secondElementValue > firstElementValue) ? -1 : 1;
  };
  return data.sort(comparer);
};

/**
 * This methods helps you to validate the domain of email id
 * It bascically checks if its valid nike mail id
 * returns the boolean value.
 * @param {string} emailId which needs to be validated
 * @returns {boolean} based on the validation
 */

export const validateStakeHolderEmailDomain = (emailId: string = '') => {
  let validated = false;
  const emailSubStrings = emailId.split('@');
  if (emailSubStrings.length === 2 && (emailSubStrings[1].toLowerCase() === 'nike.com')) {
    validated = true;
  }
  return validated;
};

/**
 * This methods helps you to validate email id
 * returns the boolean value.
 * @param {string} emailId which needs to be validated
 * @returns {boolean} based on the validation
 */

export const validateStakeHolderEmail = (emailId: string) => {
  let emailValidation = false;
  const emailRegx = /^[-!#$%&'*+0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  if (emailRegx.test(String(emailId).toLowerCase())) {
    emailValidation = true;
  }
  return emailValidation;
};

/**
 * This method is used to get the Id from the session storage
 * and to remove the Id from session storage
 */
export const readSessionStorage = (key: string) => {
  const value = sessionStorage.getItem(key);
  sessionStorage.removeItem(key);
  return value;
};

/**
 * This method is used to validate if the pathName exists
 * And to validate if validateId is a string
 */
export const validatePathName = (pathName: string, validateId: string) => {
  const requiredPathNames = ['conversationthreads', 'posearch', 'poreport', 'ppmreport', 'pochangereport', 'lineitemreport', 'conversationreport', 'airfreightreport', 'gacchangehistoryreport'];
  const hasPath = requiredPathNames.find((name) => pathName.includes(name));
  return hasPath && typeof validateId === 'string';
};

/**
 * This action is used for building the request for default stakeholders api
 * @param {Array<string>} ids the array of selected po number ids
 * @returns {Object} request body for default stake holders api
 */
export const getDefaultStakeHoldersRequest = (
  poDataRows: Array<Object>, type: string, ids: Array<string> = []
) => {
  const selectedRows = (ids.length > 0)
    ? poDataRows.filter((row) => {
      if (type === 'excludes') {
        return !ids.includes(row[fieldMapping.SIZE_ID]);
      }
      return ids.includes(row[fieldMapping.SIZE_ID]);
    })
    : poDataRows;

  return {
    poData: selectedRows.map((row) => ({
      id: row.id,
      [fieldMapping.PURCHASE_ORDER_NUMBER]: row[fieldMapping.PURCHASE_ORDER_NUMBER],
      [fieldMapping.PO_LINE_ITEM_NUMBER]: row[fieldMapping.PO_LINE_ITEM_NUMBER],
      [fieldMapping.SCHEDULE_LINE_ITEM_NUMBER]: row[fieldMapping.SCHEDULE_LINE_ITEM_NUMBER]
    }))
  };
};

export const getDynamicSuccessText = (
  key: string,
  firstValue: number | string,
  secondValue: number | string
) => {
  if (!messageText[key]) {
    return '';
  }
  let dynamicText = messageText[key];
  const text1 = 'line';
  const text2 = 'is';
  const text3 = 'lines';
  const text4 = 'are';
  const totalLength = (firstValue !== null && typeof firstValue !== 'undefined')
    ? firstValue.toString()
    : '';
  const validatedLength = (secondValue !== null && typeof secondValue !== 'undefined')
    ? secondValue.toString()
    : '';
  dynamicText = dynamicText.replace('{{value1}}', totalLength);
  dynamicText = dynamicText.replace('{{value2}}', validatedLength);
  if (!firstValue || firstValue <= 1) {
    dynamicText = dynamicText.replace('{{lines}}', text1);
  } else {
    dynamicText = dynamicText.replace('{{lines}}', text3);
  }
  if (!secondValue || secondValue <= 1) {
    dynamicText = dynamicText.replace('{{are}}', text2);
  } else {
    dynamicText = dynamicText.replace('{{are}}', text4);
  }
  return dynamicText;
};

/**
 * This function is used to get the conversation thread search query string
 * to be supplied to get request
 * @param {Object} inputData from the conversation search panel
 * @returns {String} the search query to be passed in the get request
 */
export const getConversationThreadSearchRequest = (inputData: Object) => {
  const sortParam = '&sort=lastConversationDate';
  let searchQuery = '';

  const collaborationTopicCodeQuery = (key) => {
    const collaborationTopic = (inputData[key] && inputData[key].map)
      ? inputData[key].map(({ id }) => id)
      : [];
    if (collaborationTopic.length > 0) {
      searchQuery += `&filter=${key}(${collaborationTopic.join()})`;
    }
  };

  const vendorCodesQuery = (key) => {
    const vendorCodes = (inputData[key] && inputData[key].map)
      ? inputData[key].map(({ id }) => id)
      : [];
    if (vendorCodes.length > 0) {
      searchQuery += `&filter=${key}(${vendorCodes.join()})`;
    }
  };

  Object.keys(inputData).forEach((key) => {
    switch (key) {
      case 'isStakeholder': {
        const isStakeholderValue = inputData[key] ? 'Y' : 'N';
        searchQuery += `&filter=${key}(${isStakeholderValue})`;
        break;
      }
      case 'lastConversationDate': {
        const { from, to } = inputData[key];
        if (from) {
          const fromDate = getDateInYYYYMMDD(from);
          searchQuery += `&filter=${key}After(${fromDate}T00:00:00.000Z)`;
        }
        if (to) {
          const toDate = getDateInYYYYMMDD(to);
          searchQuery += `&filter=${key}Before(${toDate}T23:59:59.999Z)`;
        }
        break;
      }
      case 'vendorCodes': {
        vendorCodesQuery(key);
        break;
      }
      case 'collaborationTopic.code': {
        collaborationTopicCodeQuery(key);
        break;
      }
      default: {
        if (inputData[key].length > 0) {
          searchQuery += `&filter=${key}(${inputData[key]})`;
        }
        break;
      }
    }
  });

  return `${searchQuery}${sortParam}`;
};

/**
 * This function is used to get the conversation thread search query string
 * to be supplied to get request
 * @param {Object} inputData from the conversation search panel
 * @returns {String} the search query to be passed in the get request
 */
export const getAFAdjustmentSearchRequest = (inputData: Object) => {
  let searchQuery = '';

  const pmoQuery = (key) => {
    const vendorCodes = (inputData[key] && inputData[key].map)
      ? inputData[key].map(({ id }) => id)
      : [];
    if (vendorCodes.length > 0) {
      searchQuery += `${key}(${vendorCodes.join()}),`;
    }
  };

  Object.keys(inputData).forEach((key) => {
    switch (key) {
      case 'createdDate': {
        const { from, to } = inputData[key];
        if (from) {
          const fromDate = getDateInYYYYMMDD(from);
          searchQuery += `${key}After(${fromDate}T00:00:00.000Z),`;
        }
        if (to) {
          const toDate = getDateInYYYYMMDD(to);
          searchQuery += `${key}Before(${toDate}T23:59:59.999Z),`;
        }
        break;
      }
      case 'productionNikeLiaisonOffice': {
        pmoQuery(key);
        break;
      }
      default: {
        if (inputData[key].length > 0) {
          searchQuery += `${key}(${inputData[key]}),`;
        }
        break;
      }
    }
  });
  searchQuery = `&filter=${searchQuery.slice(0, -1)}`;
  return searchQuery;
};

/**
 * Returns the filter dropdown values from the flattened response
 * @param {Object} response from the api flattened
 * @param {*} filters array for which the unique drowpdown data do be extracted
 * @param {String} mapping type oft he mapping used
 * @returns {Object} of filter drowdown values
 */
export const getFilterDropdownValuesFromResponse = (
  response: Object,
  filters: Array<string>,
  mappingType: string
) => {
  const filterValues = {

  };
  filters.forEach((filter) => {
    const fieldProperties = getFieldProperties(filter, mappingType);
    const uniqueValues = {};
    const temp = {};
    response.forEach((row) => {
      if (row[filter]) {
        if (
          (mappingType === 'pmoDec' && filter === 'managingUserEmail')
          || (mappingType === 'geoGlobal' && filter === 'email')
          || (mappingType === 'geoEmailGroup' && filter === 'emailID')
        ) {
          temp[row[filter].toLowerCase()] = true;
        } else if (mappingType === 'geoEmailGroup' && filter === 'geoEmailList') {
          row[filter].forEach((geoEmailList) => {
            uniqueValues[geoEmailList.emailID.toLowerCase()] = true;
          });
        } else if (fieldProperties.secondary && row[fieldProperties.secondary]) {
          uniqueValues[`${row[filter]} | ${row[fieldProperties.secondary]}`] = true;
        } else {
          uniqueValues[row[filter]] = true;
        }
      }
    });
    if (
      (mappingType === 'pmoDec' && filter === 'managingUserEmail')
      || (mappingType === 'geoGlobal' && filter === 'email')
      || (mappingType === 'geoEmailGroup' && filter === 'emailID')
    ) {
      filterValues[filter] = Object.keys(temp);
    } else if (mappingType === 'gacReasonUsecases' && filter === 'isActive') {
      filterValues[filter] = ['Active', 'Inactive'];
    } else {
      filterValues[filter] = Object.keys(uniqueValues);
    }
  });
  return filterValues;
};

export const getFilteredResults = (response: Array<Object>, filterValues: Object, type: string) => (
  response.filter((row) => (
    Object.keys(filterValues).every((key) => {
      const fieldProperties = getFieldProperties(key, type);
      const secondaryValue = (fieldProperties.secondary && row[fieldProperties.secondary])
        ? ` | ${row[fieldProperties.secondary] || ''}`
        : '';
      let result;
      if (row[key] !== undefined && row[key].length > 0 && filterValues[key].length > 0 && (
        (type === 'pmoDec' && key === 'managingUserEmail')
        || (type === 'geoGlobal' && key === 'email')
        || (type === 'geoEmailGroup' && key === 'emailID')
      )) {
        result = (
          !filterValues[key]
          || filterValues[key].length === 0
          || filterValues[key].includes(row[key].toLowerCase() + secondaryValue)
        );
      } else if ((type === 'geoEmailGroup') && row[key].length > 0 && filterValues[key].length > 0 && (key === 'geoEmailList')) {
        row[key].forEach((obj) => {
          if (obj.emailID !== undefined && filterValues[key].includes(obj.emailID.toLowerCase())) {
            result = true;
          }
        });
      } else {
        result = (
          !filterValues[key]
          || filterValues[key].length === 0
          || filterValues[key].includes(row[key] + secondaryValue)
        );
      }
      return result;
    })
  ))
);
export const lookupDropdownOptionByValue = (
  selectedValue: Object,
  dropdownValues: Array<object>,
  options?: Object
) => {
  if (!selectedValue || !dropdownValues) {
    return null;
  }
  let text = 'text';
  let value = 'value';

  if (options && options.optionProps) {
    text = options.optionProps.text;
    value = options.optionProps.value;
  }
  let selectedValueCode = selectedValue;
  if (typeof selectedValue === 'object') {
    selectedValueCode = selectedValue[value];
  }

  const dropdownValue = dropdownValues.find((option) => option[value] === selectedValueCode) || {};
  const optionText = (dropdownValue[text] || '').split(' | ');
  const returnValue = { ...dropdownValue, [text]: optionText[1] || optionText[0] };
  return returnValue;
};

export const lookupDropdownOptionsByArrayValue = (
  selectedValue: Array<object>,
  dropdownValues: Array<object>,
  options?: Object
) => {
  if (!selectedValue || !dropdownValues) {
    return [];
  }
  let id = 'id';
  let returnValue = [];

  if (options && options.optionProps) {
    id = options.optionProps.id;
  }

  if (Array.isArray(selectedValue) && selectedValue[0] !== undefined) {
    const selectedItems = selectedValue.map((item) => item[id] || item);
    returnValue = dropdownValues.filter((option) => selectedItems.includes(option[id]));
  } else if (typeof selectedValue === 'string') {
    const selectedItems = selectedValue.split(',').map((item) => item.trim());
    returnValue = dropdownValues.filter((option) => selectedItems.includes(option[id]));
  }

  return returnValue;
};

export const checkForColumnData = (
  state: Object, isCalledFromSlice?: boolean
) => (
  isCalledFromSlice
    ? (
      state.searchpaneldata
      && state.searchpaneldata.selectedColumnOrderOptions
      && state.searchpaneldata.selectedColumnOrderOptions.length > 0
    )
    : (
      state.SearchPanelData
      && state.SearchPanelData.selectedColumnOrderOptions
      && state.SearchPanelData.selectedColumnOrderOptions.length > 0
    )
);

export const sortedFilterData = (data: Object) => {
  if (typeof data !== 'object' || data === null) {
    return 'Invalid data format.';
  }
  const sortedData = {};
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key]) && data[key].length > 0) {
      sortedData[key] = [...data[key]]?.sort();
    } else {
      sortedData[key] = data[key];
    }
  });
  return sortedData;
};

export const sortDataAscendingConfig = (data: Object) => {
  const sortedData = {};
  Object.keys(data).forEach((key) => {
    if (key === 'plantCode') {
      sortedData[key] = data[key];
    } else if (key === 'purchaseGroup') {
      sortedData[key] = data[key];
    } else if (Array.isArray(data[key]) && data[key].length > 0) {
      sortedData[key] = data[key].sort((a, b) => {
        const valueA = a.toLowerCase();
        const valueB = b.toLowerCase();
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      });
    } else {
      sortedData[key] = data[key];
    }
  });
  return sortedData;
};

export const getPPMHeadersForGroupedColumn = (column: string, label: string) => {
  let obj = [];
  if (column === 'groupedColumn') {
    switch (label) {
      case 'Asian C&C Label':
        obj = [
          ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_KOREA_CHEST_SIZE],
          ppmReportFieldProperties[ppmReportFieldMapping.PPM_REPORT_CHINA_TOP_SIZE]
        ];
        break;
      default: obj = [];
        break;
    }
  }
  return obj;
};

export const getPOSearhHeadersForGroupedColumn = (column: string, label: string) => {
  let obj = [];
  if (column === 'groupedColumn') {
    switch (label) {
      case 'Asian C&C Label':
        obj = [
          poSearchFieldProperties[fieldMapping.POSEARCH_KOREA_CHEST_SIZE],
          poSearchFieldProperties[fieldMapping.POSEARCH_CHINA_TOP_SIZE]
        ];
        break;
      default: obj = [];
        break;
    }
  }
  return obj;
};

export const setFieldItemVas = (requestBody: Object) => {
  let updatedPayload = requestBody;
  if (requestBody?.fields
        && requestBody.fields.includes('poLine.itemVasDetail')
        && !requestBody?.fields.includes('poLine.itemVas')) {
    const fieldsData = [...requestBody.fields];
    const itemDetailsIndex = fieldsData.indexOf('poLine.itemVasDetail');
    fieldsData.splice(itemDetailsIndex + 1, 0, 'poLine.itemVas');
    updatedPayload = { ...requestBody, fields: fieldsData };
  }
  return updatedPayload;
};

export const setFilterAsiaSize = (requestBody: Object) => {
  let updatedPayload = { ...requestBody };
  if (requestBody?.filter) {
    const filters = [...requestBody.filter];
    const relevantFilterIndex = filters.findIndex(
      (filterItem) => filterItem.fieldName === 'poLine.asianSpecificSizeIsRelevant'
        && (
          filterItem.fieldValue?.includes('NO'))
    );
    if (relevantFilterIndex !== -1) {
      const relevantFilter = { ...filters[relevantFilterIndex] };
      relevantFilter.operator = 'OR';
      relevantFilter.function = 'NOT IS_DEFINED';
      filters[relevantFilterIndex] = relevantFilter;
      updatedPayload = { ...requestBody, filter: filters };
    }
  }
  return updatedPayload;
};

export const setValidFields = (
  fieldProperties: Object,
  payload: Object,
  dataTableHeaderInactv: Object = [],
  inactiveFeatureFields: Object = []
) => {
  const { primaryFields, secondaryFields, additionalFields } = Object.values(fieldProperties)
    .reduce((acc, item) => {
      if (!inactiveFeatureFields.includes(item.primary)) {
        acc.primaryFields.push(item.primary);
        acc.secondaryFields.push(item.secondary);
        acc.additionalFields.push(item.additionalFields);
      }
      return acc;
    }, {
      primaryFields: [],
      secondaryFields: [],
      additionalFields: []
    });
  const inactiveFields = dataTableHeaderInactv.length > 0 ? dataTableHeaderInactv?.map(
    (headRow: Object) => headRow.primary
  ) : [];
  const validFields = [
    ...primaryFields,
    ...secondaryFields,
    ...additionalFields,
    ...inactiveFields
  ];
  const checkValid = payload?.fields.filter((item) => validFields.includes(item));
  return {
    ...payload,
    fields: checkValid
  };
};
