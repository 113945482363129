/**
 * @flow
 */
import gacReasonUsecasesFieldMapping from '../fieldMappings/gacReasonUsecasesFieldMapping';

export function validateNumeric(value: string) {
  const regex = /^\d+(\.\d{2})?$/;
  if (!regex.test(value) || Number(value) < 1) {
    return 'Enter Valid Input Value.';
  }
  return '';
}

export function validate(value: string) {
  console.log('value', value);
  if (
    this.isRequired
    && (
      (typeof value === 'string' && !value.trim())
      || (typeof value !== 'string' && !value)
    )
  ) {
    return 'This is a mandatory field.';
  }
  switch (this.primary) {
    case 'useCaseCode': {
      return validateNumeric(value);
    }
    default: {
      return '';
    }
  }
}

export default {
  [gacReasonUsecasesFieldMapping.GAC_REASON_USESCASES_REASON_CODE]: {
    primary: gacReasonUsecasesFieldMapping.GAC_REASON_USESCASES_REASON_CODE,
    secondary: gacReasonUsecasesFieldMapping.GAC_REASON_USESCASES_REASON_CODE_DESCRIPTION,
    type: 'string',
    link: false,
    label: 'GAC Reason Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    validate,
    validationType: 'numeric',
    isEditable: false,
    isRequired: true,
    gridSpan: 4
  },
  [gacReasonUsecasesFieldMapping.GAC_REASON_USESCASES_REASON_CODE_DESCRIPTION]: {
    primary: gacReasonUsecasesFieldMapping.GAC_REASON_USESCASES_REASON_CODE_DESCRIPTION,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Description',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    validate,
    isEditable: false,
    isRequired: true,
    gridSpan: 4
  },
  [gacReasonUsecasesFieldMapping.GAC_REASON_USESCASES_DIVISION]: {
    primary: gacReasonUsecasesFieldMapping.GAC_REASON_USESCASES_DIVISION,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Division',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    validate,
    isEditable: false,
    isRequired: true,
    gridSpan: 2
  },
  [gacReasonUsecasesFieldMapping.GAC_REASON_USESCASES_CODE]: {
    primary: gacReasonUsecasesFieldMapping.GAC_REASON_USESCASES_CODE,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Use Case Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    isEditable: false,
    isRequired: true,
    gridSpan: 2,
    validate
  },
  [gacReasonUsecasesFieldMapping.GAC_REASON_USESCASES_CODE_DESCRIPTION]: {
    primary: gacReasonUsecasesFieldMapping.GAC_REASON_USESCASES_CODE_DESCRIPTION,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Use Case Code Description',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    isEditable: true,
    isRequired: true,
    gridSpan: 4,
    validate
  },
  [gacReasonUsecasesFieldMapping.GAC_REASON_USESCASES_STATUS]: {
    primary: gacReasonUsecasesFieldMapping.GAC_REASON_USESCASES_STATUS,
    secondary: '',
    type: 'boolean',
    link: false,
    label: 'Active/Inactive',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    isEditable: true,
    isRequired: true,
    gridSpan: 4
  },
  [gacReasonUsecasesFieldMapping.GAC_REASON_USESCASES_LAST_UPDATED_BY]: {
    primary: gacReasonUsecasesFieldMapping.GAC_REASON_USESCASES_LAST_UPDATED_BY,
    secondary: '',
    type: 'string',
    link: false,
    label: 'Last Edited By',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    isEditable: false,
    gridSpan: 4
  },
  [gacReasonUsecasesFieldMapping.GAC_REASON_USESCASES_LAST_UPDATED_DATE]: {
    primary: gacReasonUsecasesFieldMapping.GAC_REASON_USESCASES_LAST_UPDATED_DATE,
    secondary: '',
    type: 'datetime',
    link: false,
    label: 'Last Edited On',
    wrap: true,
    numeric: 'true',
    disablePadding: false,
    group: true,
    isEditable: false,
    gridSpan: 4
  }
};
