/* @flow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import { toggleSpinner } from '../dialogStates/dialogStatesSlice';
import getflattenResponse, { cryptoRand } from '../../helpers/common';
import { getFilteredResults, getFilterDropdownValuesFromResponse } from '../../helpers/dataHelpers';
import sortFilterData from './gacReasonUsecaseHelper';
import gacReasonUsecasesMock from '../mock/gacReasonUsecasesMock';

export const name = 'gacReasonUseCases';

function createInitialState() {
  return {
    filterDropdownValues: {
    },
    selectedFilters: {
      usecaseGacReasonCode: [],
      divType: [],
      isActive: []
    },
    dataId: '',
    settingsFilterId: '',
    SettingsData: [],
    pmoRequestBody: null,
    dataRows: gacReasonUsecasesMock,
    gacReasonUseCasesData: null,
    unfilteredConfigScreenData: null
  };
}

export const initialState = createInitialState();

function createReducers() {
  // UPDATE_GAC_REASON_USE_CASES_SCREEN_RESULTS
  function updateGacReasonCodeUsecasesResults(state: Object, action: Object) {
    const payload = {
      data: action.payload.updateddata,
      filteredResults: action.payload.filteredResults || action.payload.updateddata
    };

    return {
      ...state,
      gacReasonUseCasesData: payload.filteredResults,
      unfilteredConfigScreenData: payload.data,
      dataId: cryptoRand().toString().substr(2, 8)
    };
  }
  // CLEAR_GAC_REASON_USE_CASES_FILTERS
  function clearGacReasonCodeUsecaseFilters(state: Object) {
    return {
      ...state,
      selectedFilters: {
        ...initialState.selectedFilters
      },
      gacReasonUseCasesData: state.unfilteredConfigScreenData
    };
  }
  // UPDATE_GAC_REASON_USE_CASES_FILTERED_RESULTS
  function updateFilteredGACUsecasesConfigScreen(state: Object, action: Object) {
    const payload = {
      data: action.payload
    };
    return {
      ...state,
      gacReasonUseCasesData: payload.data,
      dataId: cryptoRand().toString().substr(2, 8)
    };
  }
  // ADD_GAC_REASON_USE_CASES_FILTER_VALUES
  function updateFilterValues(state: Object, action: Object) {
    if (action.payload.values instanceof Array && action.payload.filter) {
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.payload.filter]: action.payload.values
        }
      };
    }
    return { ...state };
  }
  // SETTINGS_UPDATE_FILTER_DROPDOWN_VALUES
  function updateFilterDropdownValues(state: Object, action: Object) {
    if (
      action.payload && Object.keys(action.payload).length > 0
    ) {
      return {
        ...state,
        filterDropdownValues: sortFilterData(action.payload)
      };
    }
    return { ...state };
  }
  // SET_SETTINGS_FILTER_ID
  function updatePMORequestFilterId(state: Object) {
    return { ...state, settingsFilterId: cryptoRand().toString().substr(2, 8) };
  }

  return {
    updateGacReasonCodeUsecasesResults,
    clearGacReasonCodeUsecaseFilters,
    updateFilteredGACUsecasesConfigScreen,
    updateFilterValues,
    updateFilterDropdownValues,
    updatePMORequestFilterId
  };
}

export const reducers = createReducers();
export const slice = createSlice({ name, initialState, reducers });

const actions = { ...slice.actions };

function createExtraActions() {
  function getGacReasonCodeUsecasesResults() {
    return createAsyncThunk(
      `${name}/getGacReasonCodeUsecasesResults`,
      async (
        { callback },
        { getState, dispatch }
      ) => {
        dispatch(toggleSpinner(true));
        request({
          api: 'getReasonCodeUsecases',
          method: 'get'
        })
          .then((response) => {
            const state = getState();
            const flattenedResponse = getflattenResponse(response?.data?.objects);
            const updateddata = [];
            flattenedResponse.forEach((row) => {
              const { reasonCode: _, ...modifiedRow } = row;
              modifiedRow.isActive = row.isActive ? 'Active' : 'Inactive';
              modifiedRow.usecaseGacReasonCode = row.reasonCode;
              updateddata.push(modifiedRow);
            });
            const { selectedFilters } = state.GacReasonUseCases;
            const filteredResults = getFilteredResults(
              updateddata,
              selectedFilters,
              'gacReasonUsecases'
            );
            dispatch(actions.updateGacReasonCodeUsecasesResults({
              updateddata,
              filteredResults
            }));
            dispatch(actions.updateFilterDropdownValues(
              getFilterDropdownValuesFromResponse(updateddata, [
                'usecaseGacReasonCode',
                'divType',
                'isActive'
              ], 'gacReasonUsecases')
            ));
            dispatch(toggleSpinner(false));
            if (callback) {
              callback(response);
            }
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            console.log(error);
            callback(error);
          });
      }
    );
  }

  function addNewGacReasonCodeUsecasesDetails() {
    return createAsyncThunk(
      `${name}/addNewGacReasonCodeUsecasesDetails`,
      async (
        {
          data,
          callback
        },
        { dispatch }
      ) => {
        const updatedData = data?.map((row) => ({
          reasonCode: row.usecaseGacReasonCode.value,
          reasonCodeDescription: row.usecaseGacReasonCode.label,
          divType: row.divType.value,
          useCaseCode: row.useCaseCode,
          useCaseDescription: row.useCaseDescription
        }));
        const usecaseRequestBody = {
          gacData: updatedData
        };
        dispatch(toggleSpinner(true));
        request({
          api: 'AddGacUsecaseContacts',
          method: 'post',
          data: usecaseRequestBody
          // cancellable: true
        })
          .then((response) => {
            dispatch(toggleSpinner(false));
            callback(response.data);
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            callback(null, error);
          });
      }
    );
  }

  function updateGacUsecaseDetails() {
    return createAsyncThunk(
      `${name}/updateGacUsecaseDetails`,
      async (
        {
          data,
          callback
        },
        { dispatch }
      ) => {
        if (data) {
          const updateRequestBody = {
            type: 'update',
            gacData: data
          };
          dispatch(toggleSpinner(true));
          request({
            api: 'updateGacUsecaseContacts',
            method: 'put',
            data: updateRequestBody
            // cancellable: true
          })
            .then((response) => {
              dispatch(toggleSpinner(false));
              callback(response.data);
            })
            .catch((error) => {
              dispatch(toggleSpinner(false));
              callback(null, error);
            });
        }
      }
    );
  }

  function applyFilters() {
    return createAsyncThunk(
      `${name}/applyFilters`,
      async (
        { payload },
        { getState, dispatch }
      ) => {
        try {
          dispatch(actions.updateFilterValues(payload));
          const state = getState();
          const { selectedFilters, unfilteredConfigScreenData } = state.GacReasonUseCases;
          const filteredResults = getFilteredResults(unfilteredConfigScreenData, selectedFilters, 'gacReasonUsecases');
          dispatch(actions.updateFilteredGACUsecasesConfigScreen(filteredResults));
        } catch (error) {
          console.log(error);
        }
      }
    );
  }

  function clearFilters() {
    return createAsyncThunk(
      `${name}/clearFilters`,
      async (_, { dispatch }) => {
        dispatch(actions.clearGacReasonCodeUsecaseFilters());
      }
    );
  }

  function getGacReasonCodeResults() {
    return createAsyncThunk(
      `${name}/getGacReasonCodeResults`,
      (callback, { dispatch }) => {
        request({
          api: 'getGACReasonCode',
          method: 'get'
        })
          .then((response) => {
            const resData = response.data;
            const oldData = resData.objects;
            const modifiedData = [];
            oldData.forEach((row) => {
              const updatedRow = row;
              updatedRow.isActive = (row.isActive) ? 'Active' : 'Inactive';
              modifiedData.push(updatedRow);
            });
            if (callback) callback(resData);
          })
          .catch((error) => {
            console.log('getGacReasonCodeResults->error', error);
            dispatch(toggleSpinner(false));
            if (callback) callback(error);
          });
      }
    );
  }

  function getGACReasonCodeActiveResults() {
    return createAsyncThunk(
      `${name}/getGACReasonCodeActiveResults`,
      ({ callback }, { dispatch }) => {
        request({
          api: 'getGACReasonCodeActive',
          method: 'get'
        })
          .then((response) => {
            const resData = response.data;
            if (callback) callback(resData);
          })
          .catch((error) => {
            console.log('getGACReasonCodeActiveResults->error', error);
            dispatch(toggleSpinner(false));
            if (callback) callback(error);
          });
      }
    );
  }

  return {
    getGacReasonCodeUsecasesResults: getGacReasonCodeUsecasesResults(),
    addNewGacReasonCodeUsecasesDetails: addNewGacReasonCodeUsecasesDetails(),
    updateGacUsecaseDetails: updateGacUsecaseDetails(),
    applyFilters: applyFilters(),
    clearFilters: clearFilters(),
    getGacReasonCodeResults: getGacReasonCodeResults(),
    getGACReasonCodeActiveResults: getGACReasonCodeActiveResults()
  };
}

export const extraActions = createExtraActions();

export const gacReasonUseCasesActions = { ...actions, ...extraActions };
export const gacReasonUseCasesReducer = slice.reducer;
