/**
 * @flow
 */

export function validateNumeric(value: string) {
  const regex = /^\d+$/;
  if (!regex.test(value) && value) {
    return 'Enter Valid Input Value.';
  }
  return '';
}

export function validate(value: string) {
  if (this.isRequired && !value.trim()) {
    return 'This is a mandatory field.';
  }

  switch (this.primary) {
    case 'ConversationCode': {
      return validateNumeric(value);
    }
    case 'ConversationDescription':
    default: {
      return '';
    }
  }
}

export default {
  ConversationCode: {
    primary: 'ConversationCode',
    secondary: '',
    type: 'string',
    link: false,
    label: 'Conversation Code',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    validate,
    validationType: 'numeric',
    isEditable: true,
    isRequired: true,
    gridSpan: 2,
    width: '20%'
  },
  conversationCategory: {
    primary: 'conversationCategory',
    secondary: '',
    type: 'string',
    link: false,
    label: 'PO Rejection',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    validate,
    validationType: 'numeric',
    isChecked: true,
    isRequired: false,
    gridSpan: 2,
    width: '20%'
  },
  ConversationDescription: {
    primary: 'ConversationDescription',
    secondary: '',
    type: 'string',
    link: false,
    label: 'Description',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    validate,
    isConstantEditable: true,
    isRequired: true,
    gridSpan: 4
  },
  modifiedBy: {
    primary: 'modifiedBy',
    secondary: '',
    type: 'string',
    link: false,
    label: 'Last Edited By',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    isRequired: true,
    gridSpan: 4
  },
  modifiedDate: {
    primary: 'modifiedDate',
    secondary: '',
    type: 'string',
    link: false,
    label: 'Last Edited On',
    wrap: true,
    numeric: 'false',
    disablePadding: false,
    group: true,
    isRequired: true,
    gridSpan: 4
  }
};
